// import 'slick-carousel'
import GET_ARTICLES from '../plugins/getArticles'

const [ w, l, d, mq ] = [ window, location, document, window.matchMedia( "(max-width: 900px)" ) ]

const init = () => {
  w.jQuery = $

  //header透過
  $.when( w.load['header'] ).then( () => {
    $.scrollspy({
      trigger: 10,
      onBefore(/* pos */) {
        $('.p-header').addClass('is-transparent');
      },
      onAfter() {
        $('.p-header').removeClass('is-transparent');
      }
    })
  });

  /****************************
   * movie background
   */
  $.when(
    w.load['mainvisual'],
    $.getScript('/asset/js/vendor/jquery.mb.YTPlayer.min.js')
  ).then( () => {
    const $MV = $('[data-plugin="ytplayer"]');
    const param = id => `{
      videoURL: '${id}',
      containment: 'body',
      mute: true,
      showControls: false,
      autoPlay: true,
      useOnMobile: true,
      showYTLogo: false,
      abundance: '${(mq.matches)? 0.01: 0.07}',
      stopMovieOnBlur: false,
      loop: true,
      startAt: 0,
      anchor: 'center,center'
    }`.replace(/\n|\s/g,'')
    $MV.attr('data-property',param('NzNEf3Skrgs'));
    $MV.YTPlayer()
    $MV.YTPPlay()

    $MV.on('YTPStart', () => {
      $('.mainvisual').addClass('is-active');
    });

  });

  // ticker
  $.when( w.load['mainvisual'] ).then( () => {
    let makeItem = item => $(`
      <a href="${item.href}" class="link">
        <span class="date">${item.date}</span>
        <span class="cat">[${item.category.name}]</span>
        <span class="subject">${item.title.str_truncate(30)}</span>
      </a>
    `)[0];
    GET_ARTICLES( '.block-ticker__article[data-plugin="getArticles"]', { makeItem });
  });

  // news
  $.when(w.load['block-news']).then( () => {
    let makeItem = item => $(`
    <li class="block-news-item ${item.is_new ? 'is-new': ''}">
      <a href="${item.href}">
        <span class="date-cate">
          <span class="date">${item.date}</span>
          <span class="cate">${item.category.name}</span>
        </span>
        <span class="subject">${item.title.str_truncate(26)}</span>
      </a>
    </li>
    `)[0]
    GET_ARTICLES('.block-news__list[data-plugin="getArticles"]', { makeItem })
  })
}

export default function HOME() {
  $( init )
}
