import VIEWPORTS from '../plugins/viewports'
import PARTSLOADER from '../plugins/partsloader'
import CONTENT_TITLE from '../plugins/contentTitle'

// スクロールして画面エリアに入ったら要素を表示する lazydisp
import LAZYDISP from '../plugins/lazydisp'

// IE11でSVGの外部参照を有効にする
import 'svgxuse'

// IEでのpictureタグ対応
import IE_SRCSET from '../plugins/polyfill-ie-srcset'

const [w,d,l] = [window, document, location];

export default function INIT() {
  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'footer': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'mapScript': new $.Deferred, // googlemapAPIのURLパラメータでresolveします
    'loadHtml': new $.Deferred, // すべてのLoad Htmlが完了したらresolveする
  }

  //header.html, footer.html読み込み
  PARTSLOADER([
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      target: 'footer',
      method:'replaceWith',
      callback(){
        $('footer [data-plugin="getCopyright"]').getCopyright()
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      target: 'header',
      method:'replaceWith',
      callback(r,_){
        $('header .p-gnav li:not(.is-recruit)').currentMenu({ mode:1, default:999 });

        //採用情報記事にいるときはcurrentMenuのURLを完全一致にする
        if( l.pathname == '/news/' && l.search == '?mode=detail&article=1' ) {
          $('header .p-gnav li').currentMenu({ mode:0, default:999, url: '/news/?mode=detail&article=1'});
        }

        const mq = window.matchMedia( "(min-width: 900px)" )
        const $logos = $('.p-header__logo img[data-home-src]')
        const checkBreakpoint = (mq) => {
          if( location.pathname.replace(/(main|index)\.php/,'') === '/' && mq.matches ){
            $logos.each( (i,o) => $(o).attr({ 'src': o.dataset.homeSrc }) )
          }else{
            $logos.each( (i,o) => $(o).attr({ 'src': o.dataset.defaultSrc }) )
          }
        }
        mq.addListener( checkBreakpoint )
        checkBreakpoint(mq)
      }
    }
  ])
  // プライバシー・会社概要・サイトマップのコンテンツタイトル
  CONTENT_TITLE({
    'sitemap':'<span>SITEMAP</span>',
    'privacy':'<span>PRIVACY POLICY</span>',
    'profile':'会社概要'
  })

  //IE pictureタグ対応
  IE_SRCSET();

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // カレントメニュー
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    })
    // ページトップリンクをスクロール量で表示するサンプル
    $.scrollspy({
      trigger:200,
      onBefore(/* pos */) {
        $('.p-pagetop__wrap').addClass('hidden');
      },
      onAfter() {
        $('.p-pagetop__wrap').removeClass('hidden');
      }
    })

    //コンテンツヘッダー内にパンくずリストを移動
    if( w.load['loadHtml'] ) {
      w.load['loadHtml'].then(() => {
        $('.mainImgInner').append($('.breadcrumbs'));
      });
    }

    // lazydisp
    $.when( window.load['loadHtml'] ).then( () => LAZYDISP('[data-lazydisp]') )
  })

}