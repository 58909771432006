const [ w, d, content ] = [window, document, 'service'];

const init = () => {
}

const replace_item = obj => {
  let href = obj.getAttribute('href')
  let thumb = obj.querySelector('.thum_img > img').getAttribute('src')
  let is_new = obj.querySelectorAll('span.new').length > 0 ? true : false
  let catObj = obj.querySelector('.catetitle a')
  let cat = catObj ? catObj.innerText : ''
  let dateObj = obj.querySelector('p.date')
  let date = dateObj ? dateObj.innerText : false
  let title = obj.querySelector('h5 > a').getAttribute('title')
  let desc = obj.querySelector('p.catch')? obj.querySelector('p.catch').innerText : ''

  $(obj).replaceWith(`
  <a href="${href}" class="${content}-item" data-lazydisp="bottom">
    <div class="${content}-item__row">
      <figure class="${content}-item__img">
        <div class="${content}-item__img-cover" style="background-image:url(${thumb ? thumb : '/image/underconstruction.gif'})">
          <img src="${thumb ? thumb : '/image/underconstruction.gif'}" width="420" decoding="async">
        </div>
      </figure>
      <div class="${content}-item__body">
        <h4 class="subject"><span>${title.str_truncate(32)}</span></h4>
        <span class="desc">${desc.str_truncate(42)}</span>
      </div>
    </div>
  </a>
  `)
}

const list_init = () => {
  $('.contents .wrapLink', '.element_list').each((i, obj) => replace_item(obj) );

  const listHead = $.get('/asset/html/service-list-head.html');
  listHead.done( html => {
    $('.element_list').prepend(html);
  });
}

// 詳細ページにて実行
const detail_init = async post_id => {
  let the_article = await $.getArticles({ content, post_id, post_status: 'all' });
  let categories = await $.getCategories({ content });
  the_article = the_article.shift();
  the_article.category = categories.filter( c => c.id == the_article.category ).shift();

  // 詳細置き換え
  $('.element_detail').each( ( i, elm_detail ) => {
    let $head = $(`
      <div class="${content}-detail-head">
        <div class="${content}-detail-head__row">
          <div class="${content}-detail-head__body">
            <h3 class="${content}-detail-head__title">${the_article.title}</h3>
            <span class="desc">${the_article.desc}</span>
          </div>
          <figure class="${content}-detail-head__img">
            <div class="${content}-detail-head__img-cover" style="background-image:url(${the_article.thumb})">
              <img src="${the_article.thumb}" alt="${the_article.title}" width="500" loading="lazy">
            </div>
          </figure>
        </div>
      </div>
    `)
    $('.element_detail > .title, .detail_title').remove()
    $('.element_detail .entry > figure.imgbox').remove()

    $head.prependTo( elm_detail )

    //記事フッター
    const detailFoot = $.get('/asset/html/service-detail-foot.html');
    detailFoot.done( html => {
      $('#main .block.block_foot').append(html);
      $('.service-detail-nav__list').currentMenu({mode: 0, default: 999});
    });
  })

}

export default function SERVICE() {
  $(() => {
    init()
    if( w.query_string('mode') == 'list' || $('.element_list').length ) list_init( w.query_string('cat') );
    if( w.query_string('article') || $('.element_detail').length ) detail_init( w.query_string('article') );
  });
}