const [ w, l, mq ] = [ window, location, window.matchMedia( "(max-width: 900px)" ) ]

const init = () => {

  const companyMission = $.get('/asset/html/company-mission.html');
  companyMission.done( html => {
    $('.is--article-1 .company-info').before(html);
  });
}

export default function COMPANY() {
  $( init )
}